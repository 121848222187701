/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "../../../contents/components/MKBox";
import MKTypography from "../../../contents/components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "contents/DefaultNavbar";
import DefaultFooter from "contents/DefaultFooter";

// About Us page sections

// Routes
import routes from "../../../routes";
import footerRoutes from "../../../app/footer.routes";

// Images
import bgImage from "assets/images/bg-update-notes.jpg";
import bgImageMobile from "assets/images/bg-update-notes-mobile.jpg";
import Notes from "./sections/Notes";
import { selectImageWithWidth } from "utils/CommonUtils";

import { downloadPointieData } from "../../../data/DownloadButtons";

const UpdateNotes = () => {
  const bgImg = selectImageWithWidth(bgImage, bgImageMobile);
  return (
    <>
      <DefaultNavbar routes={routes} action={downloadPointieData} transparent light />
      <MKBox
        minHeight="35vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Update Notes
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              You can check what has been added or changed in Pointie
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Notes />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default UpdateNotes;
