/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "../../DefaultNavbar";
import DefaultFooter from "../../DefaultFooter";

// About Us page sections

// Routes
import routes from "../../../routes";
import footerRoutes from "../../../app/footer.routes";

// Images
import bgImage from "../../../assets/images/bg-privacy-policy.jpg";
import bgImageMobile from "../../../assets/images/bg-privacy-policy-mobile.jpg";
import { selectImageWithWidth } from "../../../utils/CommonUtils";

import { downloadPointieData } from "../../../data/DownloadButtons";

const PrivacyPolicy = () => {
  const bgImg = selectImageWithWidth(bgImage, bgImageMobile);
  return (
    <>
      <DefaultNavbar routes={routes} action={downloadPointieData} transparent light />
      <MKBox
        minHeight="35vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Privacy Policy
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              At Pointie, we respect your privacy and are committed to protecting your personal
              information. This Privacy Policy explains how we collect, use, and disclose
              information about you when you use our Pointie Chrome Extension.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKTypography variant="h4">Information We Collect</MKTypography>
        <MKTypography variant="body2" mb={1}>
          &nbsp;We collect information that you provide to us when you use the Extension. We may
          also collect information about your device, including your geolocation like country code,
          browser type, and operating system.
        </MKTypography>

        <MKTypography variant="h4">How We Use Your Information</MKTypography>
        <MKTypography variant="body2" mb={1}>
          &nbsp;We use the information we collect to provide and improve the Extension, to
          personalize your experience, and to communicate with you. We may also use your information
          to provide you with advertisements that are tailored to your interests.
          <br />
          &nbsp;We may share your information with third-party service providers who help us provide
          and improve the Extension. We require these providers to use your information only for the
          purposes for which it was provided and to protect it in a manner that is consistent with
          this Privacy Policy.
          <br />
          &nbsp;We may also disclose your information in response to a legal request, such as a
          subpoena or court order, or when we believe it is necessary to protect our rights or the
          rights of others.
        </MKTypography>
        <MKTypography variant="h4">Security</MKTypography>
        <MKTypography variant="body2" mb={1}>
          &nbsp;We take reasonable measures to protect your information from unauthorized access,
          disclosure, or destruction. However, no method of transmission over the internet or
          electronic storage is completely secure, and we cannot guarantee the absolute security of
          your information.
        </MKTypography>

        <MKTypography variant="h4">Changes to This Privacy Policy</MKTypography>
        <MKTypography variant="body2" mb={1}>
          &nbsp;We may update this Privacy Policy from time to time. If we make material changes, we
          will notify you by posting a notice on our website or Extension. Your continued use of the
          Extension after we make changes to this Privacy Policy will indicate your acceptance of
          the changes.
        </MKTypography>

        <MKTypography variant="h4">Contact Us</MKTypography>
        <MKTypography variant="body2" mb={3}>
          &nbsp;If you have any questions or concerns about our Privacy Policy, please contact us at{" "}
          <a href="mailto:letspointie@gmail.com">letspointie@gmail.com</a>.
        </MKTypography>

        <MKTypography variant="body1" mb={1}>
          Update: July 18, 2023
        </MKTypography>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default PrivacyPolicy;
