/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Table from "@mui/material/Table";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@mui/material/TableContainer";
import { TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    borderTop: "none",
    borderBottom: "none",
    borderLeft: "none",
    borderRight: "none",
  },
});

const Notes = () => {
  const classes = useStyles();
  const createData = (date, version, description) => {
    return { date, version, description };
  };

  const rows = [
    createData("2025.02.05", "0.5.0", "<h4>New Feature</h4><p>Earn Rate, Best Label.</p>"),
    createData("2024.07.07", "0.4.1", "<h4>UX bug fix</h4><p>Some UI bug fix.</p>"),
    createData("2024.06.13", "0.4.0", "<h4>New Feature</h4><p>Great Canadian Rebates added.</p>"),
    createData("2023.11.28", "0.3.2", "<h4>UX bug fix</h4><p>UI bug fix for Giant Tire.</p>"),
    createData(
      "2023.11.26",
      "0.3.1",
      "<h4>UX improvements</h4><p>Now, you can drag and drop Pointie pop-up. Over time, the pop-up will shrink to  the Pointie icon, and when you hover the mouse over the icon again, the pop-up will return to its normal state.</p>"
    ),
    createData(
      "2023.11.14",
      "0.1.0",
      "<h4>Activation Status Display:</h4>" +
        '<p>&nbsp;Upon clicking "Collect," an "Activated" status will now be visible for up to 2 minutes upon your return. Please note that it may not display on certain sites.</p>' +
        "<h4>Special Deals for Various Occasions:</h4>" +
        "<p>&nbsp;Pointie now showcases new and exciting deals in anticipation of Black Friday, Cyber Monday, Boxing Day, EPP, and other special events, enhancing your shopping experience.</p>" +
        "<h4>Quick Access to Pointie Website:</h4>" +
        "<p>&nbsp;Conveniently, you can now tap the icon in the pop-up to navigate directly to the Pointie website, ensuring seamless access to additional information and services.</p>" +
        "<h4>Printing Display Issue:</h4>" +
        "<p>&nbsp;We have addressed and resolved a bug that prevented the unwanted display of Pointie when printing. Your browsing and printing experiences should now be smoother and more efficient.</p>"
    ),
    createData("2023.07.18", "0.0.1", "Release to public with MVP"),
  ];

  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="version table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Version</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.version}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.version}</TableCell>
              <TableCell dangerouslySetInnerHTML={{ __html: row.description }}></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Notes;
